import React from 'react';
import RubberBand from 'react-reveal/RubberBand'
import { BsAndroid2, BsCodeSlash, BsFillDiagram3Fill, BsSearch } from 'react-icons/bs'
import { SiAdguard } from 'react-icons/si'
import Zoom from 'react-reveal/Zoom'
import './Services.css'


// Fake Data



const Services = () => {

    return (
        <section>
            <div className="px-5 lg:px-[100px]">
                <div className="text-center my-24">
                    <RubberBand>
                        <h2 className='main-title'> OUR SERVICES</h2>
                        <h2 className='main-sub'>Custom IT Solutions for Your<br /> Successful Business</h2>
                        <div className="title-design"></div>
                    </RubberBand>
                </div>
                <div className="main-service">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-5">
                        <div className='service-item bg-[#EEF9FF] p-12 common-item rounded-lg'>
                            <Zoom>
                                <div className="text-center">
                                    <div className="service-sec mb-5">
                                        <div className="service-icon">
                                            <SiAdguard className='flex justify-center text-white text-2xl font-bold rotate-45 mx-auto text-center' />
                                        </div>
                                    </div>
                                    <h2 className='main-sub2 my-3'>Cyber Security</h2>
                                    <h4 className='text-gray-600'>Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed</h4>
                                </div>
                            </Zoom>
                        </div>
                        <div className='service-item bg-[#EEF9FF] p-12 common-item rounded-lg'>
                            <Zoom>
                                <div className=" text-center">
                                    <div className="service-sec mb-5">
                                        <div className="service-icon">
                                            <BsFillDiagram3Fill className='flex justify-center text-white text-2xl font-bold rotate-45 mx-auto text-center' />
                                        </div>
                                    </div>
                                    <h2 className='main-sub2 my-3'>Data Analytics</h2>
                                    <h4 className='text-gray-600'>Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed</h4>
                                </div>
                            </Zoom>
                        </div>
                        <div className='service-item bg-[#EEF9FF] p-12 common-item rounded-lg'>
                            <Zoom>
                                <div className=" text-center">
                                    <div className="service-sec mb-5">
                                        <div className="service-icon">
                                            <BsCodeSlash className='flex justify-center text-white text-2xl font-bold rotate-45 mx-auto text-center' />
                                        </div>
                                    </div>
                                    <h2 className='main-sub2 my-3'>Web Development</h2>
                                    <h4 className='text-gray-600'>Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed</h4>
                                </div>
                            </Zoom>
                        </div>
                        <div className='service-item bg-[#EEF9FF] p-12 common-item rounded-lg'>
                            <Zoom>
                                <div className=" text-center">
                                    <div className="service-sec mb-5">
                                        <div className="service-icon">
                                            <BsAndroid2 className='flex justify-center text-white text-2xl font-bold rotate-45 mx-auto text-center' />
                                        </div>
                                    </div>
                                    <h2 className='main-sub2 my-3'>App Development</h2>
                                    <h4 className='text-gray-600'>Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed</h4>
                                </div>
                            </Zoom>
                        </div>
                        <div className='service-item bg-[#EEF9FF] p-12 common-item rounded-lg'>
                            <Zoom>
                                <div className=" text-center">
                                    <div className="service-sec mb-5">
                                        <div className="service-icon">
                                            <BsSearch className='flex justify-center text-white text-2xl font-bold rotate-45 mx-auto text-center' />
                                        </div>
                                    </div>
                                    <h2 className='main-sub2 my-3'>SEO Optimization</h2>
                                    <h4 className='text-gray-600'>Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed</h4>
                                </div>
                            </Zoom>
                        </div>
                        <div className='service-item bg-[#06A3DA] common-item rounded-lg'>
                            <Zoom>
                                <div className="p-5 text-center">
                                    <h2 className='text-2xl text-white font-bold'>Call Us For Quote</h2>
                                    <h4 className='text-white font-semibold my-5 text-[18px]'>Clita ipsum magna kasd rebum at ipsum amet dolor justo dolor est magna stet eirmod</h4>
                                    <h2 className='text-white font-extrabold text-4xl'>+012 345 6789</h2>
                                </div>
                            </Zoom>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;