import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';
import { FiTrash2 } from 'react-icons/fi'
import { BsSearch } from 'react-icons/bs'
import { toast } from 'react-toastify';

const User = () => {
    const [user, setUser] = useState([]);
    const [loadding, setLoadding] = useState(false);
    const [search, setSearch] = useState('')

    useEffect(() => {
        setLoadding(true);
        fetch(`${backendApi}/auth/allUsers`)
            .then(res => res.json())
            .then(data => {
                setUser(data);
                setLoadding(false)
            })
    }, [user])


    //Remove Single Users
    const handleDeleteUser = (id) => {
        const user = window.confirm('Do you want delete this user?');

        if (user) {
            fetch(`${backendApi}/auth/removeSingleUser/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result?.success) {
                        toast.success(result?.message)
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }


    return (
        <section>
            <h2 className='main-sub capitalize mb-3 mt-4'>Total users : {user?.length}</h2>

            {
                user?.length === 0 ? '' :
                    <>
                        <div className="user-list">
                            <div className='my-10'>
                                <h1 className=' text-[22px] font-bold text-slate-800  mb-[10px] text-center mt-24'> All  <span className='text-blue-500'>Users</span> List</h1>
                                {/* Search Box  */}
                                <div className="search-box relative flex items-center my-5  w-full md:w-1/3 ">
                                    <input type="text" placeholder='Search Name' className='rounded-lg p-2 w-full block  relative focus:ring-2 outline-0 border border-slate-400 focus:ring-blue-500 placeholder-gray-400  text-slate-800 font-semibold' onChange={(e) => setSearch(e.target.value)} />
                                    <BsSearch className='absolute right-[10px] text-gray-500' />
                                </div>

                                <div className="shadow-md  w-1/3 md:w-full overflow-x-hidden">
                                    <table className="w-full">
                                        <thead className="bg-slate-300 ">
                                            <tr className='text-center rounded-xl'>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Serial</th>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Photo</th>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Name</th>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Email</th>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Status</th>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                user?.filter(item => item.userName.toLowerCase().includes(search)).map((user, index) => {
                                                    const { userName, profile, email, _id } = user;
                                                    return (
                                                        <tr className="border border-transparent hover:border hover:border-blue-400 cursor-pointer transition my-5 ease-in-out duration-75 shadow-xl" key={index}>
                                                            <td className="px-6 py-4 text-center font-semibold text-slate-500 text-[20px]">{index + 1}</td>
                                                            <td className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                                                <div className="relative h-10 w-10">
                                                                    <img
                                                                        className="h-full w-full rounded-full object-cover object-center"
                                                                        src={profile}
                                                                        alt="profile"
                                                                    />
                                                                    <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                                                                </div>
                                                                <div className="text-sm">
                                                                    <div className="font-semibold text-gray-500 capitalize">{userName}</div>
                                                                    <div className="text-gray-500 font-semibold">{email}</div>
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 text-center text-[16px] capitalize font-semibold text-slate-500">{userName}</td>
                                                            <td className="px-6 py-4 text-center text-[16px] font-semibold text-slate-500">{email}</td>
                                                            <td className="px-6 py-4 text-center">
                                                                <span
                                                                    className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600"
                                                                >
                                                                    <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                                                                    Active
                                                                </span>
                                                            </td>
                                                            <td className="px-6 py-4 text-center">
                                                                <FiTrash2 className='text-red-400 cursor-pointer text-center text-[20px] mx-auto' onClick={() => handleDeleteUser(_id)} />
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </section>
    );
};

export default User;