import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';

const BlogDetails = () => {
    const { id } = useParams();

    const [user, setUser] = useState({});
    const [loadding, setLoadding] = useState(false);


    useEffect(() => {
        setLoadding(true);
        fetch(`${backendApi}/blogs/getSingleBlog/${id}`)
            .then(res => res.json())
            .then(data => {
                setUser(data);
                setLoadding(false)
            })
    }, [])





    return (
        <section className='my-24'>
            {
                loadding ? <>
                    <h2 className='main-sub2 text-center'>Loadding please wait....</h2>
                </>
                    :
                    <>
                        <div className='px-5 md:px-32'>
                            <div className="blog-authoror mb-5">
                                <div className="flex items-center gap-5">
                                    <div className="author-logo">
                                        <img src={user?.photo} alt="logo" className='w-12 h-12 rounded-full' />
                                    </div>
                                    <div className="auhor-title">
                                        <h2 className='text-gray-500 capitalize'>{user?.name}</h2>
                                        <h2 className='text-gray-600'>{user?.date}</h2>
                                    </div>
                                </div>
                            </div>
                            <img src={user?.thubnail} alt="photo" className='object-contain mb-3' />
                            <h2 className='main-sub2 mb-3 text-2xl'>{user?.title}</h2>
                            <div className='text-[18px]' dangerouslySetInnerHTML={{ __html: user?.disc }}></div>
                        </div>
                    </>
            }
        </section>
    );
};

export default BlogDetails;