import React from 'react';
import { Slide } from 'react-reveal'
import { FaStar, FaPhoneAlt, FaEnvelopeOpen, FaMapMarker } from 'react-icons/fa'
import Zoom from 'react-reveal/Zoom'
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';

const ProjectDetails = () => {
    const [pd, setPd] = useState({});
    const [loadding, setLoadding] = useState(false);
    const { id } = useParams();


    useEffect(() => {
        fetch(`${backendApi}/project/getSingleProject/${id}`)
            .then(res => res.json())
            .then(data => {
                console.log("object", data);
                setPd(data)
                setLoadding(false)
            })
    }, [])

    return (
        <section className='my-32'>
            <div className="px-5 lg:px-[100px]">
                <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-x-5 gap-y-12">
                    <div className="project-left">
                        <Slide left>
                            <img src={pd?.photo} alt="logo" className='object-contain' />
                            <h2 className='py-5 text-4xl font-bold capitalize'>{pd?.projectName}</h2>
                            <h5 className='text-gray-600 font-semibold text-[18px]'>{pd?.disc}</h5>
                        </Slide>
                    </div>
                    <div className="project-right lg:w-[400px] lg:mx-auto w-full">
                        <div className="project-slide ring-[1px]">
                            <Slide right>
                                <h2 className='text-white bg-blue-400 p-3 text-4xl font-bold text-center'>Project Information</h2>
                                <div className="divide-y divide-dashed divide-blue-400 ">
                                    <div className="div">
                                        <h4 className='text-[17px] font-semibold my-3 capitalize pl-5'><span className='extra-color mr-2'>Project Name :</span>{pd?.projectName}</h4>
                                    </div>
                                    <div className="div">
                                        <h4 className='text-[17px] font-semibold my-3 capitalize pl-5'><span className='extra-color mr-2'>Manager Name: </span>{pd?.projectManagerName}</h4>
                                    </div>
                                    <div className="div">
                                        <h4 className='text-[17px] font-semibold my-3 capitalize pl-5'><span className='extra-color mr-2'>Project URL : </span><a href={pd?.projectUrl} target="_blank" className='text-red-500' rel="noopener noreferrer">Click Here</a></h4>
                                    </div>
                                    <div className="div">
                                        <h4 className='text-[17px] font-semibold my-3 capitalize pl-5'><span className='extra-color mr-2'>Complete Date: </span>{pd?.finishDate}</h4>
                                    </div>
                                    <div className="div">
                                        <h4 className='text-[17px] font-semibold my-3 capitalize pl-5 '><span className='extra-color mr-2'>Client Name: </span>{pd?.clientName}</h4>
                                    </div>
                                    <div className="div">
                                        <h4 className='text-[17px] font-semibold my-3 capitalize pl-5 pb-4 flex items-center'>
                                            <span className='extra-color mr-2'>Project Rating: </span>


                                            {
                                                pd?.rating === 1 ?
                                                    <>
                                                        <ul className='flex items-center'>
                                                            <li><FaStar className='text-[#FFC107]' /></li>
                                                            <li><FaStar className='text-[#cac7bd]' /></li>
                                                            <li><FaStar className='text-[#cac7bd]' /></li>
                                                            <li><FaStar className='text-[#cac7bd]' /></li>
                                                            <li><FaStar className='text-[#cac7bd]' /></li>
                                                        </ul>

                                                    </>
                                                    :
                                                    pd?.rating === 2 ?
                                                        <>
                                                            <ul className='flex items-center'>
                                                                <li><FaStar className='text-[#FFC107]' /></li>
                                                                <li><FaStar className='text-[#FFC107]' /></li>
                                                                <li><FaStar className='text-[#cac7bd]' /></li>
                                                                <li><FaStar className='text-[#cac7bd]' /></li>
                                                                <li><FaStar className='text-[#cac7bd]' /></li>
                                                            </ul>

                                                        </> :
                                                        pd?.rating === 3 ?
                                                            <>
                                                                <ul className='flex items-center'>
                                                                    <li><FaStar className='text-[#FFC107]' /></li>
                                                                    <li><FaStar className='text-[#FFC107]' /></li>
                                                                    <li><FaStar className='text-[#FFC107]' /></li>
                                                                    <li><FaStar className='text-[#cac7bd]' /></li>
                                                                    <li><FaStar className='text-[#cac7bd]' /></li>

                                                                </ul>

                                                            </> :
                                                            pd?.rating === 4 ?
                                                                <>
                                                                    <ul className='flex items-center'>
                                                                        <li><FaStar className='text-[#FFC107]' /></li>
                                                                        <li><FaStar className='text-[#FFC107]' /></li>
                                                                        <li><FaStar className='text-[#FFC107]' /></li>
                                                                        <li><FaStar className='text-[#FFC107]' /></li>
                                                                        <li><FaStar className='text-[#cac7bd]' /></li>

                                                                    </ul>

                                                                </> :
                                                                pd?.rating === 5 ?
                                                                    <>
                                                                        <ul className='flex items-center'>
                                                                            <li><FaStar className='text-[#FFC107]' /></li>
                                                                            <li><FaStar className='text-[#FFC107]' /></li>
                                                                            <li><FaStar className='text-[#FFC107]' /></li>
                                                                            <li><FaStar className='text-[#FFC107]' /></li>
                                                                            <li><FaStar className='text-[#FFC107]' /></li>
                                                                        </ul>

                                                                    </> :


                                                                    <></>
                                            }

                                        </h4>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className="contact-now mt-5">
                            <h2 className=' p-3 text-4xl font-bold '>Contact Information </h2>
                            <div className="bg-blue-400 h-1 w-[100px] mb-5"></div>
                            <div className="counter-box">
                                <div className="counter-item bg-[#06A3DA] p-8 rounded-md" >
                                    <div className="item1">
                                        <Zoom>
                                            <div className="flex gap-5">
                                                <div className="logo">
                                                    <div className="bg-[#ffff] p-3 rounded-md">
                                                        <FaPhoneAlt className='extra-color text-3xl font-extrabold' />
                                                    </div>
                                                </div>
                                                <div className="logo">
                                                    <h2 className='text-[20px] capitalize text-white mb-1 font-semibold'>+012 345 6789</h2>
                                                    <h2 className='text-[20px] capitalize text-white mb-1 font-semibold'>+012 345 6789</h2>

                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>
                                    <div className="item1 my-4">
                                        <Zoom>
                                            <div className="flex gap-5">
                                                <div className="logo">
                                                    <div className="bg-[#ffff] p-3 rounded-md">
                                                        <FaEnvelopeOpen className='extra-color text-3xl font-extrabold' />
                                                    </div>
                                                </div>
                                                <div className="logo">
                                                    <h2 className='text-[20px] capitalize text-white mb-1 font-semibold'>info@example.com</h2>
                                                    <h2 className='text-[20px] capitalize text-white mb-1 font-semibold'>support@example.com</h2>

                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>
                                    <div className="item1">
                                        <Zoom>
                                            <div className="flex gap-5">
                                                <div className="logo">
                                                    <div className="bg-[#ffff] p-3 rounded-md">
                                                        <FaMapMarker className='extra-color text-3xl font-extrabold' />
                                                    </div>
                                                </div>
                                                <div className="logo">
                                                    <h2 className='text-[20px] capitalize text-white mb-1 font-semibold'>Happy Clients</h2>
                                                    <h2 className='text-[20px] capitalize text-white mb-1 font-semibold'>support@example.com</h2>

                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default ProjectDetails;