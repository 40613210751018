import React from 'react';
import { MdDashboard } from 'react-icons/md'
import useActiveUser from '../../Hooks/useActiveUser';
const DashHome = () => {
    const [user] = useActiveUser();

    return (
        <div className='my-32 grid content-center'>
            <MdDashboard className='extra-color text-7xl' />
            <h2 className='main-sub'>Hello <span className='text-rose-500 capitalize'>{user?.userName}</span></h2>
            <h2 className='main-sub'> Welcome to Dashboard</h2>
        </div>
    );
};

export default DashHome;