import React from 'react';
import { BsJournalPlus, BsPencilSquare, BsFillArrowLeftCircleFill, BsMapFill, Bs1Square, BsNewspaper, BsActivity, BsAirplane, BsPaperclip, BsPhone } from 'react-icons/bs'
import { NavLink } from 'react-router-dom';
import { FaUsers } from 'react-icons/fa'
import './Slidebar.css'
import { useState } from 'react';
import { MdDashboard } from 'react-icons/md';
const dashData = [
    {
        title: 'add project',
        path: 'project',
        logo: <BsJournalPlus />
    },
    {
        title: 'Write Blogs',
        path: 'write-blog',
        logo: <BsPencilSquare />
    },
    {
        title: 'users list',
        path: 'all-users',
        logo: <FaUsers />
    },
    {
        title: 'Booking services',
        path: 'booking-services',
        logo: <BsAirplane />
    },
    {
        title: 'Contact list',
        path: 'contact-list',
        logo: <BsPhone />
    },
    {
        title: 'Admin Aceess',
        path: 'admin',
        logo: <BsPaperclip />
    }
]
const SlideBar = () => {
    const [open, setOpen] = useState(false);
    const toogleBar = () => setOpen(!open);

    return (
        <div className={`bg-slate-900 h-screen relative ${open ? "w-[100px]" : "w-[250px]"}`}>
            <h2 className='text-white font-bold text-2xl px-5 py-2 extra-color flex items-center gap-2'>
                <MdDashboard />
                <span className={`${open && 'hidden'}`}> Dashboard</span>
            </h2>
            <BsFillArrowLeftCircleFill className={`text-white text-3xl absolute right-0 top-9 cursor-pointer ${open && 'rotate-180'}`} onClick={toogleBar} />
            <div className="dash-meu">
                {
                    dashData?.map((item, index) => {
                        const { title, path, logo } = item;
                        return (
                            <div className="slidebar-menu" key={index}>
                                <NavLink to={`/dashboard/${path}`} className='flex items-center gap-x-2 my-8 px-5 text-white font-bold capitalize'>
                                    <span>{logo}</span>
                                    <span className={`${open && 'hidden'}`}>{title}</span>
                                </NavLink>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default SlideBar;