import React from 'react';
import Team from '../Home/Team/Team';
import NavBG from '../../Shared/NavBG/NavBG';

const TeamPage = () => {
    return (
        <div>
            <NavBG />
            <Team />
        </div>
    );
};

export default TeamPage;