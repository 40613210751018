import React from 'react';
import Zoom from 'react-reveal/Zoom'

// partner photo 
import logo1 from '../../../../images/partner/vendor-1.jpg'
import logo2 from '../../../../images/partner/vendor-2.jpg'
import logo3 from '../../../../images/partner/vendor-5.jpg'
import logo4 from '../../../../images/partner/vendor-3.jpg'
import logo5 from '../../../../images/partner/vendor-6.jpg'
import logo6 from '../../../../images/partner/vendor-7.jpg'
import logo7 from '../../../../images/partner/vendor-9.jpg'
import logo8 from '../../../../images/partner/vendor-8.jpg'

const partnerData = [
    {
        logo: logo1
    },
    {
        logo: logo2
    },
    {
        logo: logo3
    },
    {
        logo: logo4
    },
    {
        logo: logo5
    },
    {
        logo: logo6
    },
    {
        logo: logo7
    },
    {
        logo: logo8
    }
]

const Partner = () => {
    return (
        <section className='my-24'>
            <div className="px-5 lg:px-[100px]">
                <div className="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 xl:grid-cols-8 xxl:grid-cols-8 gap-x-5 gap-y-7">
                    <Zoom>
                        {
                            partnerData?.map((item, index) => {
                                const { logo } = item;
                                return (
                                    <div className="partner-item" index={index}>
                                        <img src={logo} alt="Partner logo" className='object-contain mx-auto h-[40px]' />
                                    </div>
                                )
                            })
                        }
                    </Zoom>
                </div>
            </div >
        </section >

    );
};

export default Partner;