import React from 'react';
import { FaEnvelopeOpen, FaFacebookF, FaInstagram, FaLinkedinIn, FaMapMarker, FaPhoneAlt, FaTwitter } from 'react-icons/fa';
import './Footer.css'
import { GrTechnology } from 'react-icons/gr';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo/logo.png'

const Footer = () => {
    return (
        <section>
            <div className='w-full bg-[#091E3E] mt-24 '>
                <div className="px-5 lg:px-[100px]">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 gap-5">
                        <div className="footer-item bg-blue-400 p-5">
                            <Link to='/' className='text-center text-4xl mb-5 block font-extrabold'>
                                {/* <img src={logo} alt="logo" className='object-contain h-[100px]' /> */}
                                <div className="flex items-center gap-1 text-white uppercase">
                                    <GrTechnology className='text-pink-500' />
                                    TechCity
                                </div>
                            </Link>
                            <h5 className='text-gray-300'>Lorem diam sit erat dolor elitr et, diam lorem justo amet clita stet eos sit. Elitr dolor duo lorem, elitr clita ipsum sea. Diam amet erat lorem stet eos. Diam amet et kasd eos duo.</h5>

                            <div className="feedback relative my-5">
                                <input type="email" className='w-full block p-4 relative placeholder:text-slate-700' placeholder='Email Address' />
                                <button type="button" className='bg-slate-950 p-4 text-white absolute right-0 top-0'>SignUp</button>
                            </div>
                        </div>
                        <div className="footer-item md:py-12">
                            <h2 className='text-white text-2xl font-bold'>Get In Touch</h2>
                            <div className="footer-shape mb-5"></div>
                            <div className="footer-list">
                                <h5 className='flex items-center gap-x-2 text-white'><FaMapMarker className='text-[#06A3DA]' />123 Street, New York, USA</h5>
                                <h5 className='flex items-center gap-x-2 text-white'><FaEnvelopeOpen className='text-[#06A3DA] my-4' />info@example.com</h5>
                                <h5 className='flex items-center gap-x-2 text-white'><FaPhoneAlt className='text-[#06A3DA]' />+012 345 67890</h5>
                            </div>
                            <div className="footer-social mt-5">
                                <ul className='flex items-center gap-x-2'>
                                    <li className='bg-[#06A3DA] p-2 rounded-md hover:bg-rose-500'>
                                        <a href="https://www.youtube.com/">
                                            <FaFacebookF className='text-white text-[16px] font-bold' />
                                        </a>
                                    </li>
                                    <li className='bg-[#06A3DA] p-2 rounded-md hover:bg-rose-500'>
                                        <a href="https://www.youtube.com/">
                                            <FaTwitter className='text-white text-[16px] font-bold' />
                                        </a>
                                    </li>
                                    <li className='bg-[#06A3DA] p-2 rounded-md hover:bg-rose-500'>
                                        <a href="https://www.youtube.com/">
                                            <FaLinkedinIn className='text-white text-[16px] font-bold' />
                                        </a>
                                    </li>
                                    <li className='bg-[#06A3DA] p-2 rounded-md hover:bg-rose-500'>
                                        <a href="https://www.youtube.com/">
                                            <FaInstagram className='text-white text-[16px] font-bold' />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-item md:py-12">
                            <h2 className='text-white text-2xl font-bold'>Quick Links</h2>
                            <div className="footer-shape mb-5"></div>
                            <div className="footer-list">
                                <ul className='footer-menu'>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   about us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   our services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   meet the team
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   letest blogs
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   contact us
                                        </Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="footer-item md:py-12">
                            <h2 className='text-white text-2xl font-bold'>Popular Links</h2>
                            <div className="footer-shape mb-5"></div>
                            <div className="footer-list">
                                <ul className='footer-menu'>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   about us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   our services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   meet the team
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   letest blogs
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='flex items-center gap-x-2 text-white'>
                                            <BsArrowRight className='text-[#06A3DA]' />   contact us
                                        </Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#061429] p-5">
                <h5 className='text-white text-center'>©Copyright {new Date().getFullYear()} . All Rights Reserved</h5>
            </div>
        </section>
    );
};

export default Footer;