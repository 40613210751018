import React from 'react';
import logo from '../../images/extra/home.gif'

const HomeLoadding = () => {
    return (
        <div className='intro-logo'>
            <img src={logo} alt="logo" className='object-contain mx-auto' />
        </div>
    );
};

export default HomeLoadding;