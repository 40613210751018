import React from 'react';
import About from '../Home/About/About';
import AboutCounter from './AboutCounter/AboutCounter';
import Team from '../Home/Team/Team';
import NavBG from '../../Shared/NavBG/NavBG';

const AboutPage = () => {
    return (
        <div>
            <NavBG />
            <About />
            <AboutCounter />
            <Team />
        </div>
    );
};

export default AboutPage;