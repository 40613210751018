import React from 'react';
import { Link } from 'react-router-dom';

const NoMatch = () => {
    return (
        <section>
            <div className="px-5 lg:px-[100px]">
                <div className='text-center my-28'>
                    <h2 className='text-[80px] font-extrabold'>4<span>0</span>4</h2>
                    <h2 className='main-sub font-extrabold'>Page Not Found</h2>
                    <h4 className='md:w-[40%] md:mx-auto text-gray-600 my-4'>We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</h4>
                    <button className='main-btn'>
                        <Link to='/'>
                            Go Back to Home
                        </Link>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default NoMatch;