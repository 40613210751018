import React from 'react';
import AddProject from './AddProject';
import ProjectModal from './ProjectModal';
import { useEffect } from 'react';
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';
import { useState } from 'react';
import { toast } from 'react-toastify';

const ProjectData = () => {
    const [pd, setPd] = useState([]);

    useEffect(() => {
        fetch(`${backendApi}/project/getAllProject`)
            .then(res => res.json())
            .then(data => setPd(data))
    }, [pd])



    // Handle Remove Project 
    const handleDelete = (id) => {
        const confirmRemove = window.confirm('Do you want Remove this project?');

        if (confirmRemove) {
            fetch(`${backendApi}/project/removeProject/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(result => {
                    console.log("result", result);
                    if (result?.success) {
                        toast.success(result?.message)
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }

    return (
        <div className='px-5'>
            <div className="project-cotrol bg-blue-500 p-3 my-12 rounded-lg">
                <div className="flex items-center justify-between">
                    <h2 className='text-white font-extrabold text-[20px]'>Project Management</h2>
                    <div className="add-project">
                        <ProjectModal />
                    </div>
                </div>

            </div>
            <div className="data-project">
                {
                    pd?.length === 0 ?
                        <>
                            <h2 className='font-bold text-2xl text-center'>Project Data is Empty</h2>
                        </>
                        :
                        <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-5">
                            {
                                pd?.map((item, index) => {
                                    const { projectName, photo, _id } = item;
                                    return (
                                        <div className='common-item bg-[#e8a956b5] hover:bg-[#06A3DA] rounded-lg' key={index}>
                                            <img src={photo} alt="logo" className='object-fill w-full h-[200px] rounded-t-lg' />
                                            <div className="p-2 text-center">
                                                <h2 className='font-bold text-2xl text-center'>{projectName}</h2>
                                                <button className='bg-rose-500 rounded-lg p-2 mb-2 text-white mt-3 fw-semibold mx-auto' onClick={() => handleDelete(_id)}>Remove Project</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </div>

        </div>
    );
};

export default ProjectData;