import React, { useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa'
import { useState } from 'react';
import './Navbar.css'
import useActiveUser from '../../../Hooks/useActiveUser';
import { GoTriangleDown } from 'react-icons/go'
import { toast } from 'react-toastify';
import logo from '../../../images/logo/logo.png'

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [user] = useActiveUser();
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => setOpen(!open);
    const navigate = useNavigate();

    const handleShowProfile = () => setIsOpen(!isOpen);
    const toogleMenuBar = () => setOpen(!open)



    //Handle Log Out 
    const handlelogOut = () => {
        localStorage.removeItem('token');
        navigate('/');
        toast.success('Logout successfully Done')
    }


    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setShow(true)
            } else {
                setShow(false)
            }
        })
    }, [])



    return (
        <div className={`bg-slate-700 w-full px-5 relative z-50 py-[1px] ${show ? 'fixed top-0 left-0 right-0 z-50 bg-white border-b-2 border-gray-300' : ''}`}>
            <div className="lg:flex lg:items-center lg:justify-between">
                <div className="flex items-center justify-between">
                    <div className="main-logo">
                        <Link to='/' className='text-white font-extrabold text-3xl uppercase'>
                            <img src={logo} alt="logo" className='object-contain h-[100px]' />
                            {/* TechCity */}
                        </Link>
                    </div>
                    <div className="memu-bars">
                        <FaBars className='text-2xl lg:hidden cursor-pointer ' onClick={toogleMenuBar} />
                    </div>
                </div>
                <div className="header-menu">
                    <ul className={`lg:flex lg:items-center gap-8 transition ease-in-out duration-75 ${open ? 'lg:block' : 'hidden'}`}>
                        <li>
                            <Link to='/' onClick={toogleMenuBar} className={show ? 'text-slate-800' : 'text-white'}>Home</Link>
                        </li>
                        <li>
                            <NavLink to='/about' onClick={toogleMenuBar} className={show ? 'text-slate-800' : 'text-white'}>about us</NavLink>
                        </li>
                        <li>
                            <NavLink to='/services' onClick={toogleMenuBar} className={show ? 'text-slate-800' : 'text-white'}>services</NavLink>
                        </li>
                        <li>
                            <NavLink to='/projects' onClick={toogleMenuBar} className={show ? 'text-slate-800' : 'text-white'}>projects</NavLink>
                        </li>
                        <li>
                            <NavLink to='/price' onClick={toogleMenuBar} className={show ? 'text-slate-800' : 'text-white'}>pricing</NavLink>
                        </li>
                        <li>
                            <NavLink to='/blogs' onClick={toogleMenuBar} className={show ? 'text-slate-800' : 'text-white'}>blogs</NavLink>
                        </li>
                        <li>
                            <NavLink to='/team' onClick={toogleMenuBar} className={show ? 'text-slate-800' : 'text-white'}>team</NavLink>
                        </li>
                        <li>
                            <NavLink to='/contact' onClick={toogleMenuBar} className={show ? 'text-slate-800' : 'text-white'}>contact us</NavLink>
                        </li>
                        <li className='relative'>
                            {
                                user?.email ?
                                    <div className="div relative z-50">
                                        <span className='flex items-center gap-x-[2px] cursor-pointer relative' onClick={handleShowProfile}>
                                            <img src={user?.profile} alt="profile" className='h-8 w-8 rounded-full' /><GoTriangleDown className='text-gray-800' />
                                        </span>

                                        <div className={`absolute top-[60px] right-[10px] z-50 rounded-lg bg-slate-800 transition-all px-5 ease-in-out duration-75 ${isOpen ? 'md:block' : 'hidden'}`}>
                                            <div className="profile-design text-center">
                                                <img src={user?.profile} alt="profile" className='w-[50px] h-[50px] mx-auto rounded-full mt-2' />
                                                <h2 className="text-gray-300 text-[15px] my-3 font-[500] capitalize">{user?.userName}</h2>
                                            </div>
                                            <hr className='bg-gray-400' />
                                            <ul className='navbar-profile-menu'>
                                                <li>
                                                    <NavLink to="/profile" className="text-gray-300 rounded-md px-3 py-1 text-[15px]   capitalize" onClick={handleShowProfile}>Profile</NavLink>
                                                </li>
                                                <hr className='bg-gray-200' />
                                                {
                                                    user?.role === 'admin' ?
                                                        <li>
                                                            <NavLink to="/dashboard" className="text-gray-300 rounded-md px-3 py-[2px] text-[15px]  capitalize" onClick={handleShowProfile}>dashboard</NavLink>
                                                        </li>
                                                        :
                                                        ''
                                                }
                                                <hr className='bg-gray-400' />

                                                <li>
                                                    <h3 className="text-gray-300 rounded-md px-3 pb-3 pt-2 text-[15px] cursor-pointer " onClick={handlelogOut} >Logout</h3>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    :

                                    <NavLink to="/login" className="text-white rounded-md p-2 text-[17px] font-medium bg-orange-500 flex items-center gap-x-[2px]" onClick={handleToggle}>Login</NavLink>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;