import React from 'react';
import Price from '../Home/Price/Price';
import FeedContact from '../Home/FeedContact/FeedContact';
import NavBG from '../../Shared/NavBG/NavBG';

const PricePage = () => {
    return (
        <div>
            <NavBG/>
            <Price />
            <div className="feed my-32">
                <FeedContact />
            </div>
        </div>
    );
};

export default PricePage;