import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { BsArrowDownUp } from 'react-icons/bs';


const ScrollToTop = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShow(true)
            } else {
                setShow(false)
            }
        })
    }, [])

    const handleGoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <div className='relative'>
            {
                show &&

                <BsArrowDownUp className='fixed bottom-[50px] right-[50px] bg-[#06A3DA] rounded-lg cursor-pointer text-white text-[40px] z-50 p-2' onClick={handleGoTop} />
            }
        </div>
    );
};

export default ScrollToTop;