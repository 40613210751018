import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';
import { useNavigate } from 'react-router-dom';

const AddProject = () => {
    const { register, handleSubmit, reset, formState: { errors }, } = useForm()
    const [loadding, setLoadding] = useState(false);
    const navigate = useNavigate();
    const imageBBAPi = '7fd567bd7022985ee88722d1243a3225'

    const onSubmit = (data) => {
        const image = data?.photo[0];

        const formData = new FormData()
        formData.append("image", image)

        fetch(`https://api.imgbb.com/1/upload?key=${imageBBAPi}`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(result => {

                if (result?.success) {
                    const imageUrl = result?.data?.url;

                    const sendData = {
                        thubnail: imageUrl,
                        projectName: data?.projectName,
                        projectManagerName: data?.projectManagerName,
                        clientName: data?.clientName,
                        projectUrl: data?.projectUrl,
                        disc: data?.disc,
                        photo: imageUrl,
                        rating: data?.rating,
                        finishDate: data?.finishDate,
                        category: data?.category
                    }

                    //send post in mongodb
                    setLoadding(true)
                    fetch(`${backendApi}/project/postProject`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(sendData)
                    })
                        .then(res => res.json())
                        .then(result => {
                            if (result.success) {
                                toast.success(result?.message)
                                navigate('/projects')
                                setLoadding(false);
                                reset();
                            } else {
                                if (result.error) {
                                    toast.error(result?.message)
                                }
                            }

                        })
                }
            })
    }




    return (
        <div className='mt-5'>
            <h2 className='main-sub capitalize mb-3'>add project</h2>

            <div className="project-box">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-2">
                        <div className="form1">
                            <input {...register("projectName", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Project Name' />
                            {errors.projectName && <span className='text-red-500'>This field is required</span>}
                        </div>
                        <div className="form1">
                            <input {...register("projectManagerName", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Project Manager Name' />
                            {errors.projectManagerName && <span className='text-red-500'>This field is required</span>}
                        </div>
                        <div className="form1">
                            <input {...register("clientName", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Client Name' />
                            {errors.clientName && <span className='text-red-500'>This field is required</span>}
                        </div>
                        <div className="form1">
                            <input {...register("finishDate", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Date' type='date' />
                            {errors.finishDate && <span className='text-red-500'>This field is required</span>}
                        </div>
                    </div>
                    <div className="form1 my-3">
                        <input {...register("projectUrl", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Project Link' type='url' />
                        {errors.projectUrl && <span className='text-red-500'>This field is required</span>}
                    </div>
                    <div className="form1 my-3">
                        <input {...register("category", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Project Category' type='text' />
                        {errors.category && <span className='text-red-500'>This field is required</span>}
                    </div>
                    <div className="form1 my-3">
                        <select {...register("rating")} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none'>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                        {errors.rating && <span className='text-red-500'>This field is required</span>}
                    </div>
                    <div className="form1 my-3">
                        <textarea {...register("disc", { required: true })} rows='3' className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Description' />
                        {errors.disc && <span className='text-red-500'>This field is required</span>}
                    </div>

                    <div className="form1 my-3">
                        <input {...register("photo", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' type='file' />
                        {errors.photo && <span className='text-red-500'>This field is required</span>}
                    </div>

                    <div className="form-btn">
                        {
                            loadding ?
                                <button className='w-full text-center capitalize text-white bg-pink-500 rounded-lg p-5 flex items-center gap-2 justify-center font-extrabold' disabled>Submitting please wait... <span className='loader'></span></button>
                                :
                                <input type="submit" className='bg-blue-400 text-white p-4 capitalize w-full cursor-pointer font-bold' value='publish project' />
                        }
                    </div>

                </form>
            </div>
        </div>
    );
};

export default AddProject;