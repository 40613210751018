import React, { useState } from 'react';
import Zoom from 'react-reveal/Zoom';
import { useForm } from 'react-hook-form';
import { TiArrowRightOutline } from 'react-icons/ti';
import { toast } from 'react-toastify';
import { BsArrowRightCircle } from 'react-icons/bs';
import { backendApi } from '../../BackendApiUrl/BackendApiUrl';



const FindAccount = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [account, setAccount] = useState('');

    const onSubmit = data => {
        fetch(`${backendApi}/auth/findAccount`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(result => {
                if (result?.message) {
                    setAccount(result?.message)
                }
                console.log(result);
                if (result?.success) {
                    toast.success(result?.message);
                    setAccount('')
                    reset();
                    localStorage.setItem('userId', result?.userId)
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                    }
                }
            })
    }

    return (
        <section className='login-section overflow-hidden'>
            <div className="container mx-auto px-5 my-32">
                <div className="login-right">
                    <Zoom>
                        <div className="login-form shadow-2xl bg-slate-800 rounded-xl p-4 mx-auto ssm:w-full sm:w-full md:w-[35%] lg:w-[35%] xl:w-[35%] xxl:w-[35%]">
                            <h2 className='text-gray-300 ext-[25px] font-medium text-center capitalize'>Password recovery </h2>
                            <h2 className='text-red-500 text-[17px] font-semibold text-center'>{account}</h2>

                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="login-details">
                                    <div className="login-form1 my-4">
                                        <h3 className='text-gray-400  font-medium pb-1 text-[17px]'>Email</h3>
                                        <input {...register("email", { required: true })} type='email' placeholder="Provide Email address" className="mt-2 flex text-white w-full items-center  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-500" />
                                        {errors.email && <span className='text-red-500 pb-2'>Email is required</span>}
                                    </div>
                                    <div className="register-form1 my-4">
                                        <button className='flex items-center gap-2  bg-gradient-to-r text-[16px] my-2 font-medium text-white w-full justify-center  from-[#0B5ED7] to-blue-500 hover:from-orange-500 hover:to-red-500 rounded-xl p-2'>Submit Recovery<BsArrowRightCircle /></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Zoom>
                </div>
            </div>
        </section>
    );
};

export default FindAccount;