import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const BlogAnimation = () => {
    return (
        <div className="px-5 lg:px-[40px]">
            <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-5">
                <div className="div">
                    <Skeleton count={1}
                        className='h-48 bg-blue-500'
                        baseColor="#51717c5c"
                    />
                </div>
                <div className="div">
                    <Skeleton count={1}
                        className='h-48 bg-blue-500'
                        baseColor="#51717c5c"
                    />
                </div>
                <div className="div">
                    <Skeleton count={1}
                        className='h-48 bg-blue-500'
                        baseColor="#51717c5c"
                    />
                </div>
            </div>
        </div>
    );
};

export default BlogAnimation;