import React from 'react';
import { BsCheck, BsCodeSlash, BsFillDiagram3Fill, BsSearch } from 'react-icons/bs';
import RubberBand from 'react-reveal/RubberBand'
import Slider from 'react-slick';
import Zoom from 'react-reveal/Zoom'
import './Testimonial.css'


//import logo
import team1 from '../../../../images/team/1.jpg'
import team2 from '../../../../images/team/2.jpg'
import team3 from '../../../../images/team/3.jpg'
import team4 from '../../../../images/team/4.jpg'



const processData = [
    {
        name: 'Shima Begum',
        title: 'profession',
        disc: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam nulla deserunt quam numquam eos voluptas tempora iste omnis facere',
        logo: team1,
        bg: 'primary'
    },
    {
        name: 'mintu rahman',
        title: 'profession',
        disc: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam nulla deserunt quam numquam eos voluptas tempora iste omnis facere',
        logo: team2
    },
    {
        name: 'natasha nata',
        title: 'profession',
        disc: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam nulla deserunt quam numquam eos voluptas tempora iste omnis facere',
        logo: team4,
        bg: 'primary'
    },
    {
        name: 'daren sami',
        title: 'profession',
        disc: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam nulla deserunt quam numquam eos voluptas tempora iste omnis facere',
        logo: team3
    }
]

const Testimonial = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <section className='my-18'>
            <div className="px-5 lg:px-[100px]">
                <div className="text-center my-24">
                    <RubberBand>
                        <h2 className='main-title'> TESTIMONIAL</h2>
                        <h2 className='main-sub'>What Our Clients Say About<br /> Our Digital Services</h2>
                        <div className="title-design"></div>
                    </RubberBand>
                </div>
                <div className="work-process">
                    <Slider {...settings}>
                        {
                            processData?.map((item, index) => {
                                const { logo, title, disc, name, bg } = item;
                                return (
                                    <Zoom>
                                        <div className="work-item" index={index}>
                                            <div className={`shadow-lg p-8 ${bg} rounded-lg `}>
                                                <div className="flex gap-x-3">
                                                    <div className="client-logo">
                                                        <img src={logo} alt="photo of user" className='object-contain h-[60px] w-[60px] rounded-xl' />
                                                    </div>
                                                    <div className="client-content">
                                                        <h2 className='text-blue-500 font-bold text-[20px] capitalize'>{name}</h2>
                                                        <h2 className='uppercase text-gray-600 font-semibold mt-2'>{title}</h2>
                                                    </div>
                                                </div>
                                                <hr className='my-5' />
                                                <h4 className='text-gray-600 font-semibold mt-5 text-[17px]'>{disc}</h4>
                                            </div>
                                        </div>
                                    </Zoom>
                                )
                            })
                        }
                    </Slider>
                </div>

            </div>
        </section >
    );
};

export default Testimonial;