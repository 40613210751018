import React from 'react';
import Services from '../Home/Services/Services';
import WorkProcess from '../Home/WorkProcess/WorkProcess';
import Testimonial from '../Home/Testimonial/Testimonial';
import NavBG from '../../Shared/NavBG/NavBG';

const ServicePage = () => {
    return (
        <div>
            <NavBG/>
            <Services />
            <WorkProcess />
            <Testimonial />
        </div>
    );
};

export default ServicePage;