import React, { useState } from 'react';
import RubberBand from 'react-reveal/RubberBand'
import { FaEnvelopeOpen, FaMapMarker, FaPhoneAlt } from 'react-icons/fa';
import Zoom from 'react-reveal/Zoom'
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Slide } from 'react-reveal'
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';
import NavBG from '../../Shared/NavBG/NavBG';


// Fake Data

const contactData = [
    {
        title: 'Call to ask any question',
        disc: '+012 345 6789',
        logo: <FaPhoneAlt />
    },
    {
        title: 'Email to get free quote',
        disc: 'info@example.com',
        logo: <FaEnvelopeOpen />
    },
    {
        title: 'Visit our office',
        disc: '123 Street, NY, USA',
        logo: <FaMapMarker />
    },


]

const ContactPage = () => {
    const { register, handleSubmit, reset, formState: { errors }, } = useForm()
    const [loadding, setLoadding] = useState(false);

    const onSubmit = (data) => {

        const storeData = {
            name: data?.name,
            email: data?.email,
            subject: data?.subject,
            message: data?.message,
            time: new Date().toDateString()
        }

        setLoadding(true);
        fetch(`${backendApi}/contact/contactPost`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(storeData)
        })
            .then(res => res.json())
            .then(result => {
                console.log(result);
                if (result?.success) {
                    toast.success(result?.message)
                    reset();
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                    }
                }
            })
    }






    return (
        <section>
            <NavBG />
            <div className="px-5 lg:px-[100px]">
                <div className="text-center my-24">
                    <RubberBand>
                        <h2 className='main-title'>CONTACT US</h2>
                        <h2 className='main-sub'>If You Have Any Query, Feel<br /> Free To Contact Us</h2>
                        <div className="title-design"></div>
                    </RubberBand>
                </div>
                <div className="main-contact">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-5">
                        <Zoom>
                            {
                                contactData?.map((item, index) => {
                                    const { title, logo, disc } = item
                                    return (
                                        <div className="counter-item  p-5" key={index}>
                                            <div className="flex gap-5">
                                                <div className="logo">
                                                    <div className="bg-[#06A3DA] p-3 rounded-md">
                                                        <h2 className='text-white text-2xl font-semibold'>{logo}</h2>
                                                    </div>
                                                </div>
                                                <div className="logo">
                                                    <h2 className='text-[19px] capitalize  mb-1 font-bold'>{title}</h2>
                                                    <h2 className='text-[24px]  font-bold extra-color'>{disc}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Zoom>
                    </div>
                </div>
                <div className="contact-google my-24">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-7">
                        <div className="contact-form">
                            <Slide left>
                                <div className="lg:w-[95%] w-full">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-2">
                                            <div className="form1">
                                                <input {...register("name", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Your Name' />
                                                {errors.name && <span className='text-red-500'>This field is required</span>}
                                            </div>
                                            <div className="form1">
                                                <input {...register("email", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Your Email' />
                                                {errors.email && <span className='text-red-500'>This field is required</span>}
                                            </div>
                                        </div>
                                        <div className="form1 my-3">
                                            <input {...register("subject", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Subject' />
                                            {errors.subject && <span className='text-red-500'>This field is required</span>}
                                        </div>
                                        <div className="form1 my-3">
                                            <textarea {...register("message", { required: true })} rows='5' className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' placeholder='Message' />
                                            {errors.message && <span className='text-red-500'>This field is required</span>}
                                        </div>


                                        <div className="form-btn">
                                            {
                                                loadding ?
                                                    <button className='w-full text-center capitalize text-white bg-pink-500 rounded-lg p-5 flex items-center gap-2 justify-center font-extrabold' disabled>Submitting please wait... <span className='loader'></span></button>
                                                    :
                                                    <input type="submit" className='bg-blue-400 text-white p-4 uppercase w-full cursor-pointer fomt-semibold' value='send Message' />
                                            }
                                        </div>

                                    </form>
                                </div>
                            </Slide>
                        </div>
                        <div className="google-map">
                            <Slide right>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d233668.38703692693!2d90.27919586057241!3d23.780573258035947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8b087026b81%3A0x8fa563bbdd5904c2!2sDhaka!5e0!3m2!1sen!2sbd!4v1688277852324!5m2!1sen!2sbd" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='w-full h-[350px]'></iframe>
                            </Slide>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default ContactPage;