import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const ServiceModal = ({ disc }) => {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    return (
        <div>
            <button onClick={onOpenModal} className='bg-blue-500 rounded-lg p-2 text-white'>Read Message</button>
            <Modal open={open} onClose={onCloseModal} center className='p-24'>
                <h2>{disc}</h2>
            </Modal>
        </div>
    );
};

export default ServiceModal;