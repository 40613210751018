import React from 'react';
import useActiveUser from '../../Hooks/useActiveUser';
import { toast } from 'react-toastify';

const Profile = () => {
    const [user] = useActiveUser();

    const handleUpdate = () => {
        toast.error('Update functionality not implements')
    }
    return (
        <section className='my-24'>
            <div className='shadow-xl box-border rounded-lg shadow-gray-500 my-12 p-2 md:w-[30%] w-full mx-auto'>
                <div className="profile-detaile">
                    <img src={user?.profile} alt="profile" className='w-[100px] h-[100px] rounded-full mx-auto my-2' />
                    <h2 className='text-slate-950 text-[20px] font-medium text-center capitalize'>{user?.userName}</h2>
                    <button className='bg-blue-500 rounded-lg p-2 text-center flex items-center justify-center mt-2 text-white mx-auto' onClick={handleUpdate}>Update Profile</button>
                </div>
            </div>
        </section>
    );
};

export default Profile;