import React from 'react';
import { FaAward, FaCheck, FaUserCog } from 'react-icons/fa'
import Zoom from 'react-reveal/Zoom'
import CountUp from 'react-countup';




const Counter = () => {
    return (
        <div className='px-12 lg:px-28'>
            <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3">
                <div className="counter-item bg-[#06A3DA] p-8" >
                    <Zoom>
                        <div className="flex gap-5">
                            <div className="logo">
                                <div className="bg-[#ffff] p-3 rounded-md">
                                    <FaUserCog className='extra-color text-3xl font-extrabold' />
                                </div>
                            </div>
                            <div className="logo">
                                <h2 className='text-[20px] capitalize text-white mb-1 font-semibold'>Happy Clients</h2>
                                <CountUp end={12345} className='text-4xl font-extrabold text-white' />
                            </div>
                        </div>
                    </Zoom>
                </div>
                <div className="counter-item bg-[#EEF9FF] p-8" >
                    <Zoom>
                        <div className="flex gap-5">
                            <div className="logo">
                                <div className="bg-[#06A3DA] p-3 rounded-md">
                                    <FaCheck className='text-white text-3xl font-extrabold' />
                                </div>
                            </div>
                            <div className="logo">
                                <h2 className='text-[20px] capitalize extra-color mb-1 font-semibold'>Projects Done</h2>
                                <CountUp end={15320} className='text-4xl font-extrabold' />
                            </div>
                        </div>
                    </Zoom>
                </div>
                <div className="counter-item bg-[#06A3DA] p-8" >
                    <Zoom>
                        <div className="flex gap-5">
                            <div className="logo">
                                <div className="bg-[#ffff] p-3 rounded-md">
                                    <FaAward className='extra-color text-3xl font-extrabold' />
                                </div>
                            </div>
                            <div className="logo">
                                <h2 className='text-[20px] capitalize text-white mb-1 font-semibold'>Win Awards</h2>
                                <CountUp end={25302} className='text-4xl font-extrabold text-white' />
                            </div>
                        </div>
                    </Zoom>
                </div>
            </div>
        </div>
    );
};

export default Counter;