import React from 'react';
import Slider from 'react-slick';
import './Hero.css'
//import photo

import logo1 from '../../../../images/hero/carousel-1.jpg'
import logo2 from '../../../../images/hero/carousel-2.jpg'
import Counter from '../../../Shared/Counter/Counter';



const slideData = [
    {
        logo: logo1
    },
    {
        logo: logo2
    }
]
const Hero = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
    };




    return (
        <div>
            <div className="hero-slide-item relative">
                <Slider {...settings}>
                    {
                        slideData?.map((item, index) => {
                            const { logo } = item;
                            return (
                                <div className="div">
                                    <div className="hero-item" index={index}>
                                        <img src={logo} alt="slide photo" className='w-full object-contain' />
                                        <div className="hero-content">
                                            <h3 className='text-[25px] font-bold text-white'>CREATIVE & INNOVATIVE</h3>
                                            <h2 className='text-[65px] font-extrabold text-white my-5'>Creative & Innovative<br />Digital Solution</h2>
                                            <div className="flex items-center hero-btn gap-5 justify-center">
                                                <button className='text-white bg-blue-400 p-4 uppercase rounded-md text-[20px] hover:bg-rose-400 transition ease-in-out duration-100 font-bold'>Get started</button>
                                                <button className='text-white bg-transparent ring-2 ring-white p-4 uppercase rounded-md text-[20px] hover:bg-blue-400 transition ease-in-out duration-100 font-bold'>contact now</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
            <div className="counter-top absolute -bottom-[340px] z-0 w-full mx-auto place-content-center justify-between">
                <Counter />
            </div>
        </div>
    );
};

export default Hero;