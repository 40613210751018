import React from 'react';
import SlideBar from './SlideBar/SlideBar';
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
    return (
        <div className="flex gap-x-8 gap-y-5">
            <SlideBar />
            <div className="port">
                <Outlet />
            </div>
        </div>
    );
};

export default Dashboard;