import React from 'react';
import { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { FaPlus } from 'react-icons/fa'
import AddBlogs from './AddBlogs';

const BlogModal = () => {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    return (
        <div>
            <button onClick={onOpenModal} className='bg-white rounded-lg p-2 text-slate-800 font-bold flex items-center gap-x-1'><FaPlus />Write Blog</button>
            <Modal open={open} onClose={onCloseModal} center classNames={{
                modal: 'projectModal'
            }}>
                <AddBlogs />
            </Modal>
        </div>
    );
};

export default BlogModal;