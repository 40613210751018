import React from 'react';
import { useState } from 'react';
import './TopMenu.css'
import { FaEnvelopeOpen, FaFacebookF, FaInstagram, FaLinkedinIn, FaMapMarkerAlt, FaPhoneAlt, FaTwitter } from 'react-icons/fa';

const TopMenu = () => {

    return (
        <section className='bg-[#091E3E] px-8 py-2 topbar'>
            <div className="flex items-center justify-between gap-3 flex-wrap flex-row">
                <div className="top-left">
                    <div className="flex items-center gap-x-8 gap-y-2 flex-wrap flex-row">
                        <h6 className='flex items-center gap-x-1 text-white'><FaMapMarkerAlt />123 Street, New York, USA</h6>
                        <h6 className='flex items-center gap-x-1 text-white'><FaPhoneAlt />+012 345 6789 698</h6>
                        <h6 className='flex items-center gap-x-1 text-white'><FaEnvelopeOpen />info@example.com</h6>
                    </div>
                </div>
                <div className="top-right">
                    <div className="flex items-center gap-2">
                        <a href="" target='_blank' className='w-6 h-6 ring-1 ring-white rounded-full p-1 flex items-center justify-center'><FaFacebookF className='text-white' /></a>
                        <a href="" target='_blank' className='w-6 h-6 ring-1 ring-white rounded-full p-1 flex items-center justify-center'><FaTwitter className='text-white' /></a>
                        <a href="" target='_blank' className='w-6 h-6 ring-1 ring-white rounded-full p-1 flex items-center justify-center'><FaLinkedinIn className='text-white' /></a>
                        <a href="" target='_blank' className='w-6 h-6 ring-1 ring-white rounded-full p-1 flex items-center justify-center'><FaInstagram className='text-white' /></a>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default TopMenu;