import React from 'react';
import RubberBand from 'react-reveal/RubberBand'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaLinkedinIn } from 'react-icons/fa'
import Zoom from 'react-reveal/Zoom';
import './Team.css'


// Fake Data
import member1 from '../../../../images/member/team-1.jpg'
import member2 from '../../../../images/member/team-2.jpg'
import member3 from '../../../../images/member/team-3.jpg'


const teamData = [
    {
        name: 'hemish drova',
        title: 'Programmer',
        logo: member1
    },
    {
        name: 'mitali mona',
        title: 'marketting',
        logo: member2
    },
    {
        name: 'hemish drova',
        title: 'animation',
        logo: member3
    },

]

const Team = () => {
    return (
        <section>
            <div className="px-5 lg:px-[100px]">
                <div className="text-center my-24">
                    <RubberBand>
                        <h2 className='main-title'>TEAM MEMBERS</h2>
                        <h2 className='main-sub'>Professional Stuffs Ready to<br /> Help Your Business</h2>
                        <div className="title-design"></div>
                    </RubberBand>
                </div>
                <div className="main-members">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-8">
                        {
                            teamData?.map((item, index) => {
                                const { logo, title, name } = item;
                                return (
                                    <Zoom>
                                        <div className="bg-[#EEF9FF] common-item" index={index}>
                                            <div className="shadow-lg text-center pb-8">
                                                <div className="member-logo">
                                                    <img src={logo} alt="team" className='object-contain w-full' />

                                                    <div className="team-social">
                                                        <ul className='flex items-center absolute bottom-3 justify-center gap-x-2'>
                                                            <li className='bg-[#06A3DA] p-3 rounded-md hover:bg-rose-500'>
                                                                <a href="https://www.youtube.com/">
                                                                    <FaFacebookF className='text-white text-2xl font-bold' />
                                                                </a>
                                                            </li>
                                                            <li className='bg-[#06A3DA] p-3 rounded-md hover:bg-rose-500'>
                                                                <a href="https://www.youtube.com/">
                                                                    <FaTwitter className='text-white text-2xl font-bold' />
                                                                </a>
                                                            </li>
                                                            <li className='bg-[#06A3DA] p-3 rounded-md hover:bg-rose-500'>
                                                                <a href="https://www.youtube.com/">
                                                                    <FaLinkedinIn className='text-white text-2xl font-bold' />
                                                                </a>
                                                            </li>
                                                            <li className='bg-[#06A3DA] p-3 rounded-md hover:bg-rose-500'>
                                                                <a href="https://www.youtube.com/">
                                                                    <FaInstagram className='text-white text-2xl font-bold' />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="member-content">
                                                    <h2 className='text-blue-400 text-2xl font-bold my-2 capitalize'>{name}</h2>
                                                    <h4 className='text-gray-600 text-semibold uppercase text-[18px] '>{title}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </Zoom>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Team;