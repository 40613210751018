import React, { useEffect } from 'react';
import { useState } from 'react';
import { FaLaptopCode, FaStar } from 'react-icons/fa';
import { MdDeveloperMode } from 'react-icons/md';
import RubberBand from 'react-reveal/RubberBand'
import Zoom from 'react-reveal/Zoom'
import NavBG from '../../Shared/NavBG/NavBG';
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';
import { Link } from 'react-router-dom';
import './Project.css'


const ProjectPage = () => {
    const [product, setProduct] = useState([]);

    useEffect(() => {
        fetch(`${backendApi}/project/getAllProject`)
            .then(res => res.json())
            .then(data => {
                setProduct(data);

            })
    }, [product])

    const handleCategory = cat => {
        const filterData = product.filter(item => item.category === cat);
        setProduct(filterData);
    }


    return (
        <section>
            <NavBG />
            <div className='mt-18 mb-28'>
                <div className="px-5 lg:px-[100px]">
                    <div className="text-center my-24">
                        <RubberBand>
                            <h2 className='main-projectName'>OUR PROJECTS</h2>
                            <h2 className='main-sub'>Projects Completed for Our<br /> Happy Clients</h2>
                            <div className="projectName-design"></div>
                        </RubberBand>
                    </div>
                    <div className="project-menu">
                        <ul className='flex items-center justify-center gap-5 my-12'>
                            <li>
                                <button onClick={() => setProduct(product)} className='bg-blue-400 p-2 rounded-sm text-white flex items-center font-semibold gap-2'><FaStar className='text-white' /> All</button>
                            </li>
                            <li>
                                <button onClick={() => handleCategory('design')} className='ring-1 p-2 rounded-sm extra-color flex items-center gap-2 font-semibold'><FaLaptopCode className='extra-color' />Design</button>
                            </li>
                            <li>
                                <button onClick={() => handleCategory('development')} className='ring-1 p-2 rounded-sm extra-color flex items-center gap-2 font-semibold'><MdDeveloperMode className='extra-color' />Development</button>
                            </li>

                        </ul>
                    </div>
                    <div className="project-process">
                        <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-x-5 gap-y-32">

                            {
                                product?.map((item, index) => {
                                    const { photo, projectName, category, _id } = item;
                                    return (
                                        <Link to={`/project-detail/${_id}`}>
                                            <div className="projects-item" index={index}>
                                                <div className="text-center relative common-data">
                                                    <img src={photo} alt="photo" className='w-full h-[300px]' />
                                                    <div className="absolute -bottom-[70px] bg-white w-full mx-auto text-center p-8">
                                                        <h2 className='main-sub2 my-3 capitalize'>{projectName}</h2>
                                                        <h2 className='text-gray-600  font-semibold capitalize'>{category}</h2>
                                                    </div>
                                                    <div className="bg-[#06A3DA] h-[5px] w-full absolute -bottom-[80px]"></div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectPage;