import React from 'react';

const Admin = () => {
    return (
        <div>
            <h2 className='main-sub capitalize mb-3 mt-24'>Admin Acess Functionality not Implements</h2>
        </div>
    );
};

export default Admin;