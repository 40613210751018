import React, { useEffect, useState } from 'react';
import RubberBand from 'react-reveal/RubberBand'
import Zoom from 'react-reveal/Zoom'


import { FaCalendar, FaUserAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { BsArrowRightShort } from 'react-icons/bs';
import { backendApi } from '../../../../BackendApiUrl/BackendApiUrl';
import BlogAnimation from '../../../Animation/BlogAnimation';



const Blogs = () => {
    const [blog, setBlog] = useState([]);

    useEffect(() => {
        fetch(`${backendApi}/blogs/getAllBlogs`)
            .then(res => res.json())
            .then(data => {
                setBlog(data);
            })
    }, [blog])

    return (
        <section>
            <div className="px-5 lg:px-[100px]">
                <div className="text-center my-24">
                    <RubberBand>
                        <h2 className='main-title'>LATEST BLOG</h2>
                        <h2 className='main-sub'>Read The Latest Articles from<br /> Our Blog Post</h2>
                        <div className="title-design"></div>
                    </RubberBand>
                </div>
                <div className="blogs-service">
                    {
                        blog?.length === 0 ? <>
                            <BlogAnimation />
                        </> :
                            <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-5">
                                {
                                    blog?.slice(0, 3).map((item, index) => {
                                        const { thubnail, title, disc, name, date, category, _id } = item;
                                        return (
                                            <div className="blogs-item bg-[#EEF9FF] overflow-hidden" index={index}>
                                                <Zoom>
                                                    <div className="shadow-lg">
                                                        <div className="blog-thubnail">
                                                            <img src={thubnail} alt="blog photo" className='object-contain rounded-t-lg overflow-hidden ' />
                                                            <h2 className='bg-[#0DA6DB] text-white  p-3 absolute left-0 top-16 rounded-r capitalize'>{category}</h2>
                                                        </div>
                                                        <div className="p-5">
                                                            <div className="flex items-center gap-x-5 mt-2">
                                                                <p className='flex items-center gap-3 text-gray-600 capitalize'><FaUserAlt className='extra-color' />{name}</p>
                                                                <p className='flex items-center gap-3 text-gray-600'><FaCalendar className='extra-color' />{date}</p>
                                                            </div>
                                                            <h2 className='main-sub2 my-3'>{title}</h2>
                                                            <div dangerouslySetInnerHTML={{ __html: disc }}></div>
                                                            <Link to={`/blogs/${_id}`} className='extra-color flex items-center font-bold'>
                                                                READ MORE <BsArrowRightShort />
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </Zoom>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default Blogs;