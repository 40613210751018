import React from 'react';
import { FaPhone, FaArrowRight, FaPhoneAlt, FaCheck } from 'react-icons/fa';
import { Slide } from 'react-reveal';
import logo from '../../../../images/extra/about.jpg'


const About = () => {
    return (
        <section>
            <div className="px-5 lg:px-[100px]">
                <div className="main-service my-24">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-12">
                        <div className="feed-left">
                            <Slide left>
                                <h2 className='main-title'>ABOUT US</h2>
                                <h2 className='main-sub'>The Best IT Solution With 10 <br /> Years of Experience</h2>
                                <div className="title-design2 mb-5"></div>

                                <h4 className='text-gray-600 text-[17px] mt-4'>Eirmod sed tempor lorem ut dolores. Aliquyam sit sadipscing kasd ipsum. Dolor ea et dolore et at sea ea at dolor, justo ipsum duo rebum sea invidunt voluptua. Eos vero eos vero ea et dolore eirmod et. Dolores diam duo invidunt lorem</h4>
                                <div className="flex items-center justify-between flex-wrap gap-5 mt-8 mb-2">
                                    <div className="replay">
                                        <h3 className='flex items-center gap-x-2 text-[20px] font-semibold'><FaCheck className='text-blue-400' /> Award Winning</h3>
                                    </div>
                                    <div className="replay">
                                        <h3 className='flex items-center gap-x-2 text-[20px] font-semibold'><FaCheck className='text-blue-400' /> 24/7 Support</h3>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between flex-wrap gap-5">
                                    <div className="replay">
                                        <h3 className='flex items-center gap-x-2 text-[20px] font-semibold'><FaCheck className='text-blue-400' /> Professional Staff</h3>
                                    </div>
                                    <div className="replay">
                                        <h3 className='flex items-center gap-x-2 text-[20px] font-semibold'><FaCheck className='text-blue-400' /> Best Services</h3>
                                    </div>
                                </div>

                                <div className="flex items-center gap-5 mt-8 mb-5">
                                    <div className="bg-blue-400 p-5 rounded-md">
                                        <FaPhoneAlt className='text-white' />
                                    </div>
                                    <div className="replay">
                                        <h3 className='text-[20px] font-semibold'>Call to ask any question</h3>
                                        <h3 className='text-[25px] font-semibold text-blue-500'>+012 345 6789</h3>
                                    </div>
                                </div>

                                <button type="button" className='main-btn mt-5'>Request A Quite</button>

                            </Slide>
                        </div>
                        <div className="feed-left lg:w-[90%] w-full mx-auto">
                            <Slide right>
                                <img src={logo} alt="user photo" className='object-contain rounded-md md:mt-8' />
                            </Slide>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    );
};

export default About;