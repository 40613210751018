import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './componets/Shared/Footer/Footer';
import Home from './componets/Pages/Home/Home';
import NoMatch from './componets/Shared/NoMatch/NoMatch';
import Navbar from './componets/Shared/Navbar/Navbar';
import ContactPage from './componets/Pages/ContactPage/ContactPage';
import AboutPage from './componets/Pages/AboutPage/AboutPage';
import ServicePage from './componets/Pages/ServicePage/ServicePage';
import PricePage from './componets/Pages/PricePage/PricePage';
import TeamPage from './componets/Pages/TeamPage/TeamPage';
import ProjectPage from './componets/Pages/ProjectPage/ProjectPage';
import ProjectDetails from './componets/Pages/ProjectPage/ProjectDetails';
import TopMenu from './componets/Shared/TopMenu/TopMenu';
import BlogPage from './componets/Pages/BlogPage/BlogPage';
import Dashboard from './componets/Dashboard/Dashboard';
import AddProject from './componets/Dashboard/AddProject/AddProject';
import DashHome from './componets/Dashboard/DashHome';
import AddBlogs from './componets/Dashboard/AddBlogs/AddBlogs';
import ScrollToTop from './componets/Shared/ScrollToTop/ScrollToTop';
import ScrollRebot from './componets/Shared/ScrollRebot/ScrollRebot';
import Login from './Authentication/Login/Login';
import Register from './Authentication/Register/Register';
import FindAccount from './Authentication/ForgetPassword/FindAccount';
import ForgetPassword from './Authentication/ForgetPassword/ForgetPassword';
import User from './componets/Dashboard/User/User';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ServiceBook from './componets/Dashboard/Service/ServiceBook';
import Contact from './componets/Dashboard/Contact/Contact';
import Admin from './componets/Dashboard/Admin/Admin';
import Profile from './Authentication/Profile/Profile';
import BlogDetails from './componets/Pages/BlogPage/BlogDetails';
import HomeLoadding from './componets/Animation/HomeLoadding';
import ProjectData from './componets/Dashboard/AddProject/ProjectData';
import BlogData from './componets/Dashboard/AddBlogs/BlogData';
import ProtectedRoute from './componets/ProtectedRoute/ProtectedRoute';


const App = () => {
  const [loadding, setLoadding] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadding(false);
    }, 1000);
  })


  return (
    <>
      {
        loadding ? <HomeLoadding />
          :
          <BrowserRouter>
            <TopMenu />
            <ScrollRebot />
            <Navbar />
            <Routes>
              {/* ============== All Pages Start Here========== */}
              <Route path='/' element={<Home />}></Route>

              {/* ============== All Pages Start Here========== */}
              <Route path='/contact' element={<ContactPage />}></Route>
              <Route path='/about' element={<AboutPage />}></Route>
              <Route path='/services' element={<ServicePage />}></Route>
              <Route path='/price' element={<PricePage />}></Route>
              <Route path='/team' element={<TeamPage />}></Route>
              <Route path='/blogs' element={<BlogPage />}></Route>
              <Route path='/blogs/:id' element={<BlogDetails />}></Route>
              <Route path='/projects' element={<ProjectPage />}></Route>
              <Route path='/project-detail/:id' element={<ProjectDetails />}></Route>

              {/* ===============AuthenticationPage Here============= */}
              <Route path='/login' element={<Login />}></Route>
              <Route path='/register' element={<Register />}></Route>
              <Route path='/find-account' element={<FindAccount />}></Route>
              <Route path='/forget-password' element={<ForgetPassword />}></Route>
              <Route path='/profile' element={<Profile />}></Route>
              {/* ===============AuthenticationPage Here============= */}

              {/* ===============DashBoard Here============= */}
              <Route path='/dashboard' element={<ProtectedRoute />}>
                <Route path='/dashboard' element={<Dashboard />}>
                  <Route index element={<DashHome />}></Route>
                  <Route path='/dashboard/project' element={<ProjectData />}></Route>
                  <Route path='/dashboard/all-users' element={<User />}></Route>
                  <Route path='/dashboard/write-blog' element={<BlogData />}></Route>
                  <Route path='/dashboard/booking-services' element={<ServiceBook />}></Route>
                  <Route path='/dashboard/contact-list' element={<Contact />}></Route>
                  <Route path='/dashboard/admin' element={<Admin />}></Route>
                </Route>
              </Route>

              {/* ===============DashBoard End============= */}



              {/* ============Not Match=========== */}
              <Route path='*' element={<NoMatch />}></Route>

            </Routes>
            <ScrollToTop />
            <ToastContainer />
            <Footer />
          </BrowserRouter >
      }
    </>
  );
};

export default App;