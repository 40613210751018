import React from 'react';
import './NavBG.css'

const NavBG = () => {

    return (
        <div className='navbar-bg'>
        </div>
    );
};

export default NavBG;