import React from 'react';
import Services from './Services/Services';
import WorkProcess from './WorkProcess/WorkProcess';
import Testimonial from './Testimonial/Testimonial';
import FeedContact from './FeedContact/FeedContact';
import Team from './Team/Team';
import About from './About/About';
import Price from './Price/Price';
import Blogs from './Blogs/Blogs';
import Partner from './Partner/Partner';
import Choose from './Choose/Choose';
import Hero from './Hero/Hero';

const Home = () => {
    return (
        <section>
            <Hero />
            <Choose />
            <About />
            <Services />
            <WorkProcess />
            <Testimonial />
            <FeedContact />
            <Team />
            <Price />
            <Blogs />
            <Partner />
        </section>
    );
};

export default Home;