import React from 'react';
import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom'
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';
import { useState } from 'react';
import { useEffect } from 'react';







const RecentBlogs = () => {
    const [blog, setBlog] = useState([]);


    useEffect(() => {
        fetch(`${backendApi}/blogs/getAllBlogs`)
            .then(res => res.json())
            .then(data => {
                setBlog(data);

            })
    }, [blog])

    return (
        <div>
            <h2 className=' p-3 text-4xl font-bold '>Recent Blogs</h2>
            <div className="bg-blue-400 h-1 w-[100px] mb-5"></div>
            <div className="recent-items">
                {
                    blog?.slice(0, 5).map((item, index) => {
                        const { thubnail, disc, _id } = item;
                        return (
                            <div className="recent-blogs common-item" index={index}>
                                <Link to={`/blogs/${_id}`} className='extra-color flex items-center font-bold'>
                                    <Zoom>
                                        <div className="flex items-center gap-2">
                                            <img src={thubnail} alt="blog photo" className='object-contain  rounded-lg h-[105px] w-[100px]' />
                                            <div dangerouslySetInnerHTML={{ __html: disc }}></div>
                                        </div>
                                    </Zoom>
                                </Link>
                            </div>
                        )
                    })
                }
            </div >
        </div >
    );
};

export default RecentBlogs;