import React from 'react';
import { FaAward, FaNetworkWired, FaPhoneAlt, FaUserAlt, FaUserAltSlash } from 'react-icons/fa';
import RubberBand from 'react-reveal/RubberBand'
import Zoom from 'react-reveal/Zoom'
import logo from '../../../../images/extra/feature.jpg'


const Choose = () => {
    return (
        <section className='my-32 choose-section'>
            <div className="px-5 lg:px-[100px]">
                <div className="text-center my-24">
                    <RubberBand>
                        <h2 className='main-title'>WHY CHOOSE US</h2>
                        <h2 className='main-sub'>We Are Here to Grow Your<br /> Business Exponentially</h2>
                        <div className="title-design"></div>
                    </RubberBand>
                </div>
                <div className="work-process">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-x-8 gap-y-8">
                        <div className="choose-item">
                            <Zoom>
                                <div className="choose-items">
                                    <div className="bg-[#0da6db] inline-block p-4 rounded-md">
                                        <FaNetworkWired className='text-white font-bold text-3xl' />
                                    </div>
                                    <h2 className='main-sub2 my-2'>Best In Industry</h2>
                                    <h3 className='text-gray-600'>Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</h3>
                                </div>
                                <div className="choose-items mt-12">
                                    <div className="bg-[#0da6db] inline-block p-4 rounded-md">
                                        <FaAward className='text-white font-bold text-3xl' />
                                    </div>
                                    <h2 className='main-sub2 my-2'>Award Winning</h2>
                                    <h3 className='text-gray-600'>Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</h3>
                                </div>
                            </Zoom>
                        </div>
                        <div className="choose-item">
                            <Zoom>
                                <div className="choose-items">
                                    <img src={logo} alt="photo" className='object-contain' />
                                </div>
                            </Zoom>
                        </div>
                        <div className="choose-item">
                            <Zoom>
                                <div className="choose-items">
                                    <div className="bg-[#0da6db] inline-block p-4 rounded-md">
                                        <FaUserAlt className='text-white font-bold text-3xl' />
                                    </div>
                                    <h2 className='main-sub2 my-2'>Professional Staff</h2>
                                    <h3 className='text-gray-600'>Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</h3>
                                </div>
                                <div className="choose-items mt-12">
                                    <div className="bg-[#0da6db] inline-block p-4 rounded-md">
                                        <FaPhoneAlt className='text-white font-bold text-3xl' />
                                    </div>
                                    <h2 className='main-sub2 my-2'>24/7 Support</h2>
                                    <h3 className='text-gray-600'>Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</h3>
                                </div>
                            </Zoom>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Choose;