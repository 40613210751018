import React from 'react';
import { BsCheck, BsCodeSlash, BsFillDiagram3Fill, BsSearch } from 'react-icons/bs';
import RubberBand from 'react-reveal/RubberBand'
import Zoom from 'react-reveal/Zoom'

const processData = [
    {
        title: 'Research',
        disc: 'Eos vero dolore eirmod diam duo lorem magna sit sea dolore sanctus sed et',
        logo: <BsSearch />
    },
    {
        title: 'Concept',
        disc: 'Eos vero dolore eirmod diam duo lorem magna sit sea dolore sanctus sed et',
        logo: <BsFillDiagram3Fill />
    },
    {
        title: 'Development',
        disc: 'Eos vero dolore eirmod diam duo lorem magna sit sea dolore sanctus sed et',
        logo: <BsCodeSlash />
    },
    {
        title: 'Finalization',
        disc: 'Eos vero dolore eirmod diam duo lorem magna sit sea dolore sanctus sed et',
        logo: <BsCheck />
    }
]

const WorkProcess = () => {
    return (
        <section className='my-18'>
            <div className="px-5 lg:px-[100px]">
                <div className="text-center my-24">
                    <RubberBand>
                        <h2 className='main-title'> WORK PROCESS</h2>
                        <h2 className='main-sub'>Step By Step Simple & Clean<br /> Working Process</h2>
                        <div className="title-design"></div>
                    </RubberBand>
                </div>
                <div className="work-process">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 gap-5">
                        <Zoom>
                            {
                                processData?.map((item, index) => {
                                    const { logo, title, disc } = item;
                                    return (
                                        <>
                                            <Zoom>
                                                <div className="common-item" index={index}>
                                                    <div className="shadow-lg p-5 text-center">
                                                        <div className="work-icon">
                                                            <h2 className='grid place-content-center my-5 text-4xl text-white text-[25px] rotate-0'>{logo}</h2>
                                                        </div>

                                                        <h2 className='main-sub2 my-3'>{title}</h2>
                                                        <h4>{disc}</h4>
                                                    </div>
                                                </div>
                                            </Zoom>
                                        </>
                                    )
                                })
                            }
                        </Zoom>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WorkProcess;