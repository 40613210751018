import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';
import { FiTrash2 } from 'react-icons/fi'
import { BsSearch } from 'react-icons/bs'
import { toast } from 'react-toastify';
import ServiceModal from './ServiceModal';

const ServiceBook = () => {
    const [user, setUser] = useState([]);
    const [loadding, setLoadding] = useState(false);

    useEffect(() => {
        setLoadding(true);
        fetch(`${backendApi}/service/getAllService`)
            .then(res => res.json())
            .then(data => {
                setUser(data);
                setLoadding(false)
            })
    }, [user])


    //Remove Single Users
    const handleDeleteUser = (id) => {
        const user = window.confirm('Do you want delete this user?');

        if (user) {
            fetch(`${backendApi}/service/removeService/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result?.success) {
                        toast.success(result?.message)
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }


    return (
        <section>
            <h2 className='main-sub capitalize mb-3 mt-4'>Total Booking Services : {user?.length}</h2>

            {
                user?.length === 0 ? '' :
                    <>
                        <div className="user-list">
                            <div className='my-10'>
                                <h1 className=' text-[22px] font-bold text-slate-800  mb-[10px] text-center mt-24'> Booking  <span className='text-blue-500'>Services</span> List</h1>

                                <div className="shadow-md  w-1/3 md:w-full overflow-x-hidden">
                                    <table className="w-full">
                                        <thead className="bg-slate-300 ">
                                            <tr className='text-center rounded-xl'>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Serial</th>
                                                {/* <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Photo</th> */}
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Name</th>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Email</th>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Service</th>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Time</th>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Messsage</th>
                                                <th className="px-6 py-4 text-[17px] text-slate-800 font-[600]">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                user?.map((user, index) => {
                                                    const { name, service, email, _id, time, disc } = user;
                                                    return (
                                                        <tr className="border border-transparent hover:border hover:border-blue-400 cursor-pointer transition my-5 ease-in-out duration-75 shadow-xl" key={index}>
                                                            <td className="px-6 py-4 text-center font-semibold text-slate-500 text-[20px]">{index + 1}</td>
                                                            <td className="px-6 py-4 text-center text-[16px] capitalize font-semibold text-slate-500">{name}</td>
                                                            <td className="px-6 py-4 text-center text-[16px] font-semibold text-slate-500">{email}</td>
                                                            <td className="px-6 py-4 text-center text-[16px] font-semibold text-slate-500">{service}</td>
                                                            <td className="px-6 py-4 text-center text-[16px] font-semibold text-slate-500">{time}</td>
                                                            <td className="px-6 py-4 text-center text-[16px] font-semibold text-slate-500"><ServiceModal disc={disc} /></td>

                                                            <td className="px-6 py-4 text-center">
                                                                <FiTrash2 className='text-red-400 cursor-pointer text-center text-[20px] mx-auto' onClick={() => handleDeleteUser(_id)} />
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </section>
    );
};

export default ServiceBook;