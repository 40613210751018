import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BsArrowRightCircle } from 'react-icons/bs';
import { MdCloudUpload } from 'react-icons/md';
import useActiveUser from '../../../Hooks/useActiveUser';
import { backendApi } from '../../../BackendApiUrl/BackendApiUrl';




const AddBlogs = () => {
    const [loadding, setLoadding] = useState(false);
    const [image, setImage] = useState('');
    const [preview, setPreview] = useState()
    const [category, setSetCategory] = useState()
    const [disc, setDisc] = useState('');
    const [title, setTitle] = useState('');
    const [user] = useActiveUser();
    const imageBBAPi = '7fd567bd7022985ee88722d1243a3225'

    const navigate = useNavigate();
    const date = new Date().toDateString();

    const editor = useRef(null);






    const handleUpload = (e) => {
        setImage(e.target.files[0])
        setPreview(URL.createObjectURL(e.target.files[0]))

    }



    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("image", image)


        // get all data form api 
        setLoadding(true)
        await fetch(`https://api.imgbb.com/1/upload?key=${imageBBAPi}`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    const imageUrl = result?.data?.url;

                    const sendData = {
                        thubnail: imageUrl,
                        title: title,
                        disc: disc,
                        photo: user?.profile,
                        name: user?.userName,
                        date: date,
                        category: category
                    }

                    //send post in mongodb
                    setLoadding(true)
                    fetch(`${backendApi}/blogs/postBlog`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(sendData)
                    })
                        .then(res => res.json())
                        .then(result => {
                            if (result.success) {
                                toast.success(result?.message)
                                navigate('/blogs')
                                setLoadding(false);
                                setSetCategory('')
                                setImage('')
                            } else {
                                if (result.error) {
                                    toast.error(result?.message)

                                }
                            }

                        })
                }
            })
    }



    return (
        <section className='notice-section overflow-hidden'>
            <div className="container mx-auto p-5 shadow-xl rounded-xl shadow-gray-200">
                <h2 className='main-sub capitalize mb-3 center'>Write Blog</h2>
                <div className="notice-extra">
                    <form onSubmit={handleSubmit}>
                        <div className="form2">
                            <label htmlFor="title" className='font-bold text-[18px] mb-2'>Title </label>
                            <input type="text" placeholder='Blog Title ' id="title" onChange={(e) => setTitle(e.target.value)} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' required />
                        </div>
                        <div className="form2 my-5">
                            <label htmlFor="description" className='font-bold text-[18px] mb-2'>Description </label>
                            <ReactQuill
                                theme="snow"
                                value={disc}
                                onChange={setDisc} />
                        </div>
                        <div className="form2">
                            <label htmlFor="ss" className='font-bold text-[18px] mb-2'>Category </label>
                            <input type="text" placeholder='Category' id="ss" onChange={(e) => setSetCategory(e.target.value)} className='p-4 focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' required />
                        </div>
                        <div className="form2 my-5">
                            <h2 className='font-bold'>Project Photo </h2>
                            {
                                image ? <img src={preview} alt="review " className='w-auto rounded-xl h-[200px] object-cover' /> : ''
                            }
                            {
                                image ? '' : <label htmlFor="fff" className='cursor-pointer'><MdCloudUpload className='inline text-[50px] text-slate-800' /> </label>
                            }

                            <input type="file" onChange={handleUpload} className='hidden' id='fff' />
                            <br />
                        </div>

                        <div className="notice-form1 my-4">
                            {
                                loadding ?
                                    <button className='bg-pink-500 w-ful rounded-lg text-center p-2 text-white font-semibold capitalize flex items-center gap-2' disabled>Blogs publishing Please wait...<span class="loader"></span> </button>
                                    :
                                    <button className='flex items-center gap-2 rounded-lg bg-gradient-to-r text-[16px] w-full text-center my-2 font-medium justify-center text-white bg-[#60A5FA] p-4'>Publish Now<BsArrowRightCircle /></button>
                            }
                        </div>

                    </form>
                </div>
            </div>
        </section >
    );
};


export default AddBlogs;