import React from 'react';
import { useState } from 'react';
import { FaPhone, FaArrowRight, FaPhoneAlt } from 'react-icons/fa';
import { Slide } from 'react-reveal';
import { toast } from 'react-toastify';
import { backendApi } from '../../../../BackendApiUrl/BackendApiUrl';

const FeedContact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [disc, setDisc] = useState('');
    const [service, setService] = useState('');
    const [loadding, setLoadding] = useState(false);

    const date = new Date().toDateString();

    // data store database 
    const handleSubmitData = (e) => {
        e.preventDefault();

        const data = {
            name: name,
            email: email,
            disc: disc,
            service: service,
            time: date
        }

        console.log(data);
        // setLoadding
        setLoadding(true);
        fetch(`${backendApi}/service/servicePost`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    toast.success(result?.message)
                    setLoadding(false);
                    setName('')
                    setEmail('')
                    setService('')
                    setDisc('')
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                    }
                }
            })

    }


    const serviceData = [
        {
            title: "Select a Services"
        },
        {
            title: "Cyber Security"
        },
        {
            title: "Data Analytics"
        },
        {
            title: "Web Development"
        },
        {
            title: "Apps Development"
        },
        {
            title: "SEO Optimization"
        }
    ]

    return (
        <section>
            <div className="px-5 lg:px-[100px] my-28">
                <div className="main-service">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-12">
                        <div className="feed-left">
                            <Slide left>
                                <h2 className='main-title'>REQUEST A QUOTE</h2>
                                <h2 className='main-sub'>Need A Free Quote? Please <br /> Free to Contact Us</h2>
                                <div className="title-design2 mb-5"></div>
                                <div className="flex items-center justify-between flex-wrap gap-5 mt-12 mb-5">
                                    <div className="replay">
                                        <h3 className='flex items-center gap-x-2 text-[20px] font-semibold'><FaArrowRight className='text-blue-400' /> Reply within 24 hours</h3>
                                    </div>
                                    <div className="replay">
                                        <h3 className='flex items-center gap-x-2 text-[20px] font-semibold'><FaPhone className='text-blue-400' />24 hrs telephone support</h3>
                                    </div>
                                </div>
                                <h4 className='text-gray-600 text-[17px] mt-4'>Eirmod sed tempor lorem ut dolores. Aliquyam sit sadipscing kasd ipsum. Dolor ea et dolore et at sea ea at dolor, justo ipsum duo rebum sea invidunt voluptua. Eos vero eos vero ea et dolore eirmod et. Dolores diam duo invidunt lorem. Elitr ut dolores magna sit. Sea dolore sanctus sed et. Takimata takimata sanctus sed.</h4>
                                <h4 className='text-gray-600 text-[17px] mt-4'>Dolor ea et dolore et at sea ea at dolor, justo ipsum duo rebum sea invidunt voluptua. Eos vero eos vero ea et dolore eirmod et. Dolores diam duo invidunt lorem. Elitr ut dolores magna sit. Sea dolore sanctus sed et. Takimata takimata sanctus sed.</h4>
                                <div className="flex items-center gap-5 mt-8 mb-5">
                                    <div className="bg-blue-400 p-5 rounded-md">
                                        <FaPhoneAlt className='text-white' />
                                    </div>
                                    <div className="replay">
                                        <h3 className='text-[20px] font-semibold'>Call to ask any question</h3>
                                        <h3 className='text-[25px] font-semibold text-blue-500'>+012 345 6789</h3>
                                    </div>
                                </div>

                            </Slide>
                        </div>
                        <div className="feed-left lg:w-[90%] w-full mx-auto">
                            <Slide right>
                                <div className="bg-blue-400 md:p-12 p-5 rounded-lg">
                                    <form onSubmit={handleSubmitData}>
                                        <div className="feed-input">
                                            <input type="text" value={name} placeholder='Your Name' className='p-5 bg-white rounded-lg outline-none focus:ring-2 w-full focus:ring-pink-500' onChange={(e) => setName(e.target.value)} required />
                                        </div>
                                        <div className="feed-input my-4">
                                            <input type="email" value={email} placeholder='Your Email' className='p-5 bg-white rounded-lg outline-none focus:ring-2 w-full focus:ring-pink-500' onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                        <div className="feed-input">

                                            <select onChange={(e) => setService(e.target.value)} className='p-5 bg-white text-gray-600 rounded-lg outline-none focus:ring-2 w-full focus:ring-pink-500'>
                                                {
                                                    serviceData?.map((item, index) => {
                                                        return (

                                                            <option value={item?.title} key={index} className='text-gray-600'>{item?.title}</option>

                                                        )
                                                    })
                                                }
                                            </select>

                                        </div>
                                        <div className="feed-input my-4">
                                            <textarea type="text" value={disc} placeholder='Message' rows='5' className=' bg-white rounded-lg outline-none focus:ring-2 w-full focus:ring-pink-500 pl-3 pt-2' onChange={(e) => setDisc(e.target.value)} required />
                                        </div>
                                        <div className="feed-input">
                                            {loadding ?
                                                <button className='w-full text-center capitalize text-white bg-pink-500 rounded-lg p-5 flex items-center gap-2 justify-center font-semibold'>Submitting please wait... <span className='loader'></span></button>
                                                :
                                                <button className='w-full text-center capitalize text-white bg-slate-950 rounded-lg p-5 font-semibold'>Request a quote</button>
                                            }

                                        </div>
                                    </form>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    );
};

export default FeedContact;