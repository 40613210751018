import React from 'react';
import { MdClose } from 'react-icons/md'
import { FaCheck } from 'react-icons/fa'
import RubberBand from 'react-reveal/RubberBand'
import './Price.css'
import { toast } from 'react-toastify';

const priceData = [
    {
        title: 'Basic Plan',
        subTitle: 'FOR SMALL SIZE BUSINESS',
        price: 49.00,
        service1: 'HTML5 & CSS3',
        service2: 'Bootstrap v5',
        service3: 'Responsive Layout',
        service4: 'Cross-browser Support',
        logo1: <FaCheck />,
        logo2: <FaCheck />,
        logo3: <MdClose />,
        logo4: <MdClose />,
        bg: 'gray'
    },
    {
        title: 'Standard Plan',
        subTitle: 'FOR MEDIUM SIZE BUSINESS',
        price: 99.00,
        service1: 'HTML5 & CSS3',
        service2: 'Bootstrap v5',
        service3: 'Responsive Layout',
        service4: 'Cross-browser Support',
        logo1: <FaCheck />,
        logo2: <FaCheck />,
        logo3: <FaCheck />,
        logo4: <MdClose />,
    },
    {
        title: 'Advanced Plan',
        subTitle: 'FOR LARGE SIZE BUSINESS',
        price: 159.00,
        service1: 'HTML5 & CSS3',
        service2: 'Bootstrap v5',
        service3: 'Responsive Layout',
        service4: 'Cross-browser Support',
        logo1: <FaCheck />,
        logo2: <FaCheck />,
        logo3: <FaCheck />,
        logo4: <FaCheck />,
        bg: 'gray'
    }
]

const Price = () => {

    return (
        <section>
            <div className="px-5 lg:px-[100px]">
                <div className="text-center my-24">
                    <RubberBand>
                        <h2 className='main-title'>PRICING PLANS</h2>
                        <h2 className='main-sub'>We are Offering Competitive<br /> Prices for Our Clients</h2>
                        <div className="title-design"></div>
                    </RubberBand>
                </div>
                <div className="main-price">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-3 gap-5">
                        {
                            priceData?.map((item, index) => {
                                const { title, subTitle, logo1, logo2, logo3, logo4, bg, service1, service2, service3, service4, price } = item;
                                return (
                                    <div className={`price-item p-6 rounded-lg common-item ${bg}`} index={index}>
                                        <div className="tab-title mb-8">
                                            <h2 className='extra-color font-extrabold text-2xl'>{title}</h2>
                                            <h2 className='text-gray-600 font-semibold mt-2'>{subTitle}</h2>
                                        </div>
                                        <h1>
                                            <sup className='text-3xl font-semibold'>$</sup>
                                            <span className='text-5xl font-extrabold mt-3'>{price}</span>
                                            <sub className='text-2xl font-bold capitalize'>/month</sub>
                                        </h1>
                                        <div className="service-list my-7">
                                            <div className="flex items-center gap-x-16">
                                                <h4 className='text-gray-500 font-semibold'>{service1}</h4>
                                                <h2 className='extra-color'>{logo1}</h2>
                                            </div>
                                            <div className="flex items-center  gap-x-16 my-5">
                                                <h4 className='text-gray-500 font-semibold'>{service2}</h4>
                                                <h2 className='extra-color '>{logo2}</h2>
                                            </div>
                                            <div className="flex items-center  gap-x-16 my-5">
                                                <h4 className='text-gray-500 font-semibold'>{service3}</h4>
                                                <h2 className='extra-color '>{logo3}</h2>
                                            </div>
                                            <div className="flex items-center  gap-x-16 my-5">
                                                <h4 className='text-gray-500 font-semibold'>{service4}</h4>
                                                <h2 className='extra-color '>{logo4}</h2>
                                            </div>
                                        </div>
                                        <button className='bg-[#2BB1E0] text-white p-3 ' onClick={() => toast.error('Order function not implements')}>Order Now</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Price;